const contractAddresses = {
  Amt: "0x6Ae0A238a6f51Df8eEe084B1756A54dD8a8E85d3",
  LiqAmt: "0x679Bd76cA0B3f037131AF9170d0462c9FfC9Bc27",
  burnVault: "0x759ab9e6BCc85feeF36aF26d2529C31d684A06d6", // Ex marketVault
  VaultAmt: "0xbdA1F804ff04656C3afbA6b1C332e0bFb501E9F5",
  VaultBtcb: "0x84a8cD271Cf9ba2119027791d342fc47A125C2D6",
  VaultBtcbLiq: "0xC480b84c451fd3a9ff3b758E1ad30DDd547e5Af4",
  Master: "0x13e98112e1c67DbE684adf3Aeb1C871F1fe6D1Ac",
  Btcb: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
  marketPlace: "0x0F9A86A03349D909a4c165D0c487c6FEb9E4f5bB", //POR AHORA CAMBORIU
  Factory: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
  LiqLocker: "0xfB0420c5E8A984173Cf0e87a05B4Bb6a13942782",
  LiqPool: "0x66cd75f1938e4f287f70f49b295207e9363f6a68",
  poolUsdtBtcb: "0x3F803EC2b816Ea7F06EC76aA2B6f2532F9892d62",
  OldVaultBtcb: "0x43C970507651512B72fB65D9242C3fe069ceCf4C",
  Router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  Usdt: "0x55d398326f99059ff775485246999027b3197955",

  //To change on prod final
  loanProtocol: "0x941EAa4D56415EA5EBA49ff117b387F2892eD06F",
  priceFeeder: "0x85a329bb796a5F5C8563845352ba73b91B6c74b4",
  oracle: "0xF89ea5AD30149F7950a0028Ab9C03Cc9b6bDEb67",
};
export default contractAddresses;
